// TODO вынести в UI kit
.block {
    padding: 20px;
    // padding-top: 10px;
    border-radius: 10px;
    background: #e0e8f0;
    -webkit-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    font-size: 14px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
    }

    &__row {
        margin-bottom: 8px;
    }

    &__title {
        margin-bottom: 4px;
    }

    &__text {
        gap: 6px;
    }
}

.controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.control {
    margin-bottom: 16px;
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }

        background-color: #a3b7ca80;
        color: #585454;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

@media screen and (min-width: 769px) {
    .block {
        font-size: 18px;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
    }
}