.tr {
    padding: 0;
    border-top: 1px solid black;

    td {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 10px;
        padding-right: 10px;
    };
}

.notification {
    color: red;
    font-size: 20px;
}

// TODO вынести в UI kit
.block {
    padding: 20px;
    border-radius: 10px;
    background: #e0e8f0;
    -webkit-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    overflow-y: auto;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px
    }

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
    }

    h3 {
        margin-top: 0;
    }
}

.controls {
    display: flex;
    gap: 12px;
}

// TODO вынос в UI kit
.table {
    margin-top: 12px;
    border-collapse: collapse;
    width: 100%;

    &__sort {
        cursor: pointer;

        &:hover {
            color: rgb(99, 99, 99);
        }
    }

    thead {
        th {
            padding: 8px 10px;
        }
    }
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 12px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }

        background-color: #a3b7ca80;
        color: #585454;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

@media screen and (min-width: 769px) {
    .block {
        font-size: 18px;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
    }
}
