.wrapper {
    height: 100vh;
    width: 100%;
    background-color: #a3b7ca;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    position: relative;
    text-align: center;
    color: #000000;
    padding: 24px;
    border: 2px solid #637277;
    border-radius: 12px;
    max-width: 380px;
    width: 100%;
    margin: 0 12px;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input {
    background-color: #ffffff;
    padding: 12px 16px;
    border-radius: 10px;
    border: 2px solid #637277;

    input {
        padding: 0;
        outline: none;
        border: none;
        width: 100%;
        background-color: inherit;
        font-size: 16px;
    }

    &:focus-within  {
        border-color: #000000;
    }
}

.buttons {
    margin-top: 20px;
}

.button {
    width: 100%;
    font-size: 16px;
    padding: 12px 16px;
    border: 0;
    border-radius: 10px;
    background-color: #637277;
    color: #FFFFFF;

    &:disabled {
        background-color: #63727731;
    }
}

.error {
    background-color: #f76a6a;
    padding: 8px;
    margin-bottom: 8px;
}