// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

.document {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: center;

    &__body {
        width: 100%;
        justify-content: space-between;
        display: flex;
        gap: 12px;
        font-size: 18px;

        a {
            overflow-wrap: anywhere;
            font-size: 14px;
        }

        b {
            font-size: 14px;
        }
    }
}

.controls {
    display: flex;
    gap: 8px;
}

@media screen and (min-width: 769px) {
    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
        flex-grow: 0;
    }

    .document {
        &__body {
            a {
                overflow-wrap: initial;
                font-size: 18px;
            }

            b {
                font-size: 18px;
            }
        }
    }
}