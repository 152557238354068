.wrapper {
    background-color: #a3b7ca;
    height: 100vh;
    overflow: hidden;
    display: flex;
}

.menu {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #384a5a;
    min-width: 280px;
    padding: 60px 20px 20px 20px;
    gap: 20px;
    box-shadow: 10px 0px 10px -4px rgba(34, 60, 80, 0.4);
    z-index: 900;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    &_opened {
        display: flex;

        a {
            text-align: center;
        }

        .button {
            margin: 0 auto;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__burger {
        cursor: pointer;
        position: relative;
        height: 44px;
        width: 44px;

        div {
            left: 2px;
            top: 20.5px;
            position: absolute;
            width: 40px;
            height: 3px;
            background-color: #FBFCFF;
        }

        &::before,
        &::after {
            left: 2px;
            position: absolute;
            content: '';
            display: block;
            width: 40px;
            height: 3px;
            background-color: #FBFCFF;
        }

        &::before {
            top: 6px;
        }
        
        &::after {
            bottom: 6px;
        }

        &_opened {
            display: block;
            div {
                display: none;
            }

            &::before {
                top: 50%;
                transform: rotate(-45deg);
            }

            &::after {
                top: 50%;
                transform: rotate(45deg);
            }
        }
    }

    a {
        font-size: 18px;
        font-weight: 400;
        color: #FBFCFF;
        text-decoration: none;
    }
}

.content {
    flex: 1;

    &::before {
        content: '';
        display: block;
        height: 64px;
    }

    &__header {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #194a7a;
        color: #FBFCFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
        height: 24px;
        -webkit-box-shadow: 0px 8px 10px -4px rgba(34, 60, 80, 0.3);
        -moz-box-shadow: 0px 8px 10px -4px rgba(34, 60, 80, 0.3);
        box-shadow: 0px 8px 10px -4px rgba(34, 60, 80, 0.3);

        &__profile {
            font-size: 14px;
        }
    }

    &__wrapper {
        max-height: calc(100% - 104px);
        padding: 20px;
        max-width: calc(100vw - 40px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 20px;
    font-weight: 600;
    border-radius: 8px;
    font-size: 12px;
    background-color: #a3b7ca;
    margin-right: auto;

    &:hover {
        background-color: #a3b7ca80;
    }
}

@media screen and (min-width: 769px) {
    .wrapper {
        &::before {
            content: '';
            display: block;
            min-width: 320px;
            width: 320px;
        }
    }

    .menu {
        display: flex;
        max-width: 280px;
        padding: 20px;
        top: 0;

        &__burger {
            display: none;
        }

        a {
            font-size: 18px;
            text-align: left;
        }

        .button {
            margin: 0 auto 0 0;
        }
    }

    .content {
        &__header {
            justify-content: flex-end;

            &__profile {
                font-size: 18px;
            }
        }

        &__wrapper {
            max-width: calc(100vw - 360px);
        }
    }

    .button {
        font-size: 14px;
    }
}