.form {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
}

.controls {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    flex-grow: 1;
}

// TODO вынос в UI kit
.input {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 12px;
    border: 2px solid #194a7a;
    flex-grow: 1;
}

.select {
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 12px;
    border: 2px solid #194a7a;
    flex-grow: 1;
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;
    flex-grow: 1;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }
        background-color: #a3b7ca80;
        color: #585454;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }
}

@media screen and (min-width: 769px) {
    .form {
        margin-bottom: 8px;
    }

    .controls {
        flex-grow: 0;
    }

    .input {
        flex-grow: initial;
        font-size: 14px;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
    }

    .select {
        font-size: 14px;
    }
}