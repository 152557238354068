.controls {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 16px;
    flex-direction: column;

    &__block {
        margin-bottom: 0px;
    }
}

// TODO вынести в UI kit
.block {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
    background: #e0e8f0;
    -webkit-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    font-size: 14px;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 6px
    }

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 1.4em;
        text-align: center;
    }
}

.documents {
    display: flex;
    gap: 10px;
    max-height: 700px;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: auto;
        flex: 1
    }
}

.file {
    display: flex;
    gap: 6px;
    padding: 12px;
    margin: 6px 0px;
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 15x -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 15px -10px rgba(0,0,0,0.75);
    background-color: #a0cfdd;
    border: 2px solid #194a7a;

    &__accepted {
        background-color: #5ab962;
    }

    &__declined {
        background-color: #e47c74;
    }

    &__hidden {
        background-color: #8b737193;
    }
}

.messanger {
    max-height: 300px;
    position: relative;

    &__messages {
        max-height: 85%;
        flex: 1;
        overflow: auto;
    }

    &__message {
        padding: 16px;
        margin: 10px;
        border-radius: 10px;
        background-color: #e5bdba;
        -webkit-box-shadow: 3px 3px 15x -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 3px 3px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 3px 3px 15px -10px rgba(0,0,0,0.75);
        word-break: break-all;

        &__date {
            text-align: end;
        }
    }

    &__input {
        position: relative;
        bottom: 0;
    }

    &__text {
        display: flex;
        gap: 12px;
        width: 100%;
        justify-content: space-between;

        &__input {
            resize: none;
            flex: 1;
            flex-basis: 70%;
            border: 2px solid #194a7a;
            padding: 8px;
            border-radius: 12px;
        }
    }
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;
    flex-grow: 1;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }
        background-color: #a3b7ca80;
        color: #585454;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

@media screen and (min-width: 769px) {
    .controls {
        flex-direction: row;
    }

    .messanger {
        max-width: 300px;
        max-height: 100%;

        &__text {
            &__input {
                flex-basis: initial;
            }
        }
    }

    .block {
        font-size: 18px;

        h2 {
            text-align: left;
        }
    }

    .file {
        margin: 12px 0;
        gap: 12px;
        align-items: center;
    }

    .documents {
        flex-direction: row;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
        flex-grow: 0;
    }
}