.form {
    display: flex;
    flex-direction: column;

    &__block {
        flex: 1;
        max-width: 400px;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        column-gap: 80px;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__row {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 4px;

        span {
            color: red;
            font-weight: 900;
        }
    }
}

.controls {
    display: flex;
    gap: 8px;
    align-self: center;
}

// TODO вынос в UI kit
.input {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 12px;
    border: 2px solid #194a7a;
    flex-grow: 1;
    width: calc(100% - 28px);
}

.error {
    font-size: 12px;
    color: #ff1100;
    font-weight: 600;
}

.select {
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 12px;
    border: 2px solid #194a7a;
}

// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }

        background-color: #a3b7ca80;
        color: #585454;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

@media screen and (min-width: 769px) {
    .form {
        &__row {
            display: block;
        }

        &__block {
            width: initial;
        }
    }

    .block {
        font-size: 18px;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
    }

    .input {
        font-size: 14px;
        flex-grow: 0;
    }
}