.tr {
    padding: 0;
    border-top: 1px solid black;

    td {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 10px;
        padding-right: 10px;
    };
}

.notification {
    color: red;
    font-size: 20px;
}