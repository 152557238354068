.file-loader {
    input[type=file] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        display: block;
        width: 0;
        height: 0;
    }

    &__files {
        margin-top: 10px;
    }
}

// TODO вынести в ui kit
.button {
    display: inline-block;
    text-align: center;
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;

    &:disabled {
        &:hover {
            background-color: #a3b7ca80;
            color: #585454;
        }

        background-color: #a3b7ca80;
        color: #585454;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }
}