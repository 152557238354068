.wrapper {
    h1 {
        margin-top: 0;
        margin-bottom: 32px;
        font-size: 28px;
    }
}

// TODO вынести в UI kit
.block {
    padding: 20px;
    border-radius: 10px;
    background: #e0e8f0;
    -webkit-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 10px -4px rgba(34, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    overflow-y: auto;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px
    }

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 769px) {
    .block {
        font-size: 18px;
    }
}