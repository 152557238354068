.list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.notification {
    color: red;
    font-size: 20px;
    font-weight: bold;
}