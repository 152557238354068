.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
}

.button {
    height: 24px;
    width: 24px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        height: inherit;
        width: inherit;
        // display: none;
    }
    &_prev {
        svg {
            transform: rotate(180deg);
        }
    }

    &:disabled {
        &:hover {
            text-decoration: none;
            cursor: default;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 769px) {
    .wrapper {
        justify-content: initial;
    }
}