.accordion {
    position: relative;
    border-radius: 24px;
    border: 2px solid #194a7a;
    padding: 20px;
    padding-top: 24px;
    padding-bottom: 0;
    cursor: pointer;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        gap: 8px;

        &__text {
            overflow: hidden;
        }

        &__icon {
            height: 22px;
            width: 22px;
            transform: rotate(90deg);
            transition: 0.2s;

            svg {
                height: inherit;
                width: inherit;
                fill: #194a7a;
            }

            &_active {
                transform: rotate(-90deg);
            }
        }
    }

    &__content {
        padding-top: 24px;
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        will-change: max-height;
        transition: all 0.25s ease-out;
        line-height: 1.4em;

        &__wrapper {
            margin-bottom: 24px;
        }
    }

    ol {
        margin: 0;
        padding-left: 24px;
    }

    ul {
        margin: 0;
        padding-left: 24px;
    }

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 768px) {
    .accordion__title__text {
        font-size: 16px;
    }
}