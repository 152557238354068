body:has(.modal-wrapper) {
    overflow: hidden;
}

.modal-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    display: flex;
    z-index: 998;
    justify-content: center;
    align-items: center;

    &_error {
        z-index: 999;
    }
}

.modal-background {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: rgba(7, 3, 37, 0.6);
}

.modal-window {
    width: 100%;
    display: flex;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    max-width: 780px;
    background-color: #ffffff;
    border-radius: 16px;
    max-height: 90vh;
    z-index: 10;
    position: relative;
    margin: 0 20px;
}

.modal-content {
    margin: 45px 0px;
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar {
        width: 5px;
        opacity: 1;
    }
    
    &::-webkit-scrollbar-track {
      margin: 50px 0px;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #6345DC;
        border-radius: 10px;
    }

    &__wrapper {
        margin: 0 30px;
        overflow-y: overlay;
        overflow-x: hidden;
    }
}

.modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
    background-color: #194a7a;
    border: 2px solid #194a7a;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #a3b7ca80;

        &::before,
        &::after {
            background-color: #585454;
        }
    }

    &::before,
    &::after {
        content: '';
        width: 26px;
        height: 2px;
        background-color: #ffffff;
        border-radius: 2px;
        position: absolute;
        top: 15px;
        left: 3px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.error-modal {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__title {
        display: flex;
        gap: 12px;
        align-items: center;

        img {
            height: 32px;
            width: 32px;
        }
    }

    h2 {
        margin: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.notify-modal {
    background-color: #FFFFFF;
    padding: 10px 14px;
    border-radius: 16px;
    box-shadow: 0px 4px 23px -10px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translate(-50%);
    bottom: 20px;
    max-width: 500px;
    width: calc(100% - 84px);
    background: linear-gradient(180deg, rgba(128,110,201,1) 20%, rgba(86,60,192,1) 100%);
    color: #FFFFFF;

    h2 {
        font-size: 16px;
    }

    p {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 500;
    }
}

.question-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    width: calc(100% - 40px);

    &__title {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    &__inputs {
        margin: 20px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
    }

    &__stars {
        display: flex;
        gap: 4px;

        svg {
            stroke: #000000;
            cursor: pointer;
        }

        path:hover {
            fill: #8357E1    
        }
    }
}

// TODO вынос в UI kit
.button {
    border: none;
    height: 40px;
    padding: 10px 20px;
    text-align: center;
    border-radius: 20px;
    color: #FFFFFF;
    cursor: pointer;
    white-space: nowrap;
    background-color: #806EC9;
    font-size: 16px;
    
    &:hover {
        background-color: #6345DC;
    }

    &_full-width {
        font-weight: 500;
        display: block;
        width: 100%;
        border-radius: 25px;
        font-size: 16px;
    }
}

// TODO вынос в UI kit
.input {
    background: #FFFFFF;
    box-shadow: 0px 4px 23px -10px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    border: none;
    padding: 10px 15px;
    height: 25px;
    width: calc(100% - 30px);
    outline: none;
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
        color: rgba(126, 123, 123, 0.5);
    }
}

// TODO вынос в UI kit
.textarea {
    background: #FFFFFF;
    box-shadow: 0px 4px 23px -10px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    border: none;
    padding: 20px 15px;
    width: calc(100% - 30px);
    flex-grow: 1;
    outline: none;
    font-weight: 500;
    resize: none;
    line-height: 1.4em;
    font-size: 16px;
    height: 175px;

    &::placeholder {
        color: rgba(126, 123, 123, 0.5);
    }

    &::-webkit-scrollbar {
        width: 5px;
        opacity: 1;
    }
    
    &::-webkit-scrollbar-track {
        margin: 15px 0px;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #6345DC;
        border-radius: 10px;
    }
}

// TODO вынос в отдельную модалку
.success-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__icon {
        height: 48px;
        width: 48px;

        img {
            height: inherit;
            width: inherit;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
    }
}