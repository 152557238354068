// TODO вынос в UI kit
.button {
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    color: #e0e8f0;
    background-color: #194a7a;
    border: 2px solid #194a7a;
    flex-grow: 1;

    &:hover {
        background-color: #a3b7ca80;
        color: #000000;
    }

    &_declined {
        background-color: #e47c74;
    }
}

.document {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: center;
    flex-direction: column;

    &__body {
        width: 100%;
        justify-content: space-between;
        display: flex;
        gap: 12px;
        font-size: 14px;
    }

    a {
        font-weight: 600;
    }
}

.controls {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

@media screen and (min-width: 769px) {
    .block {
        font-size: 18px;
    }

    .documents {
        flex-direction: row;
    }

    .document {
        flex-direction: row;

        &__body {
            font-size: 18px;
        }
    }

    .controls {
        flex-wrap: initial;
        flex-direction: row;
    }

    .button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 12px;
        flex-grow: 0;
    }
}